import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons/faCircleCheck';
import { AnimatePresence, motion } from 'framer-motion';

import { fadeIn } from 'libs/animations';
import IconAlert from 'images/icons/Warning';
import IconClose from 'images/icons/Close';
import MaxWidth from 'layouts/max-width';
import IconAngleDown from 'images/icons/AngleDown';
import useWindow from 'context/useWindow';
import BlankButton from 'components/forms/BlankButton';

//#region
const Wrap = styled.div`
	position: fixed;
	top: ${p =>
		(p.$topOffset && `calc(100px + ${p.$topOffset}px - 25px)`) || '100px'};
	width: 100%;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 99;
	padding: 25px 0 0 0;
	pointer-events: none;
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-fill-mode: forwards;
	opacity: 0;
	${p =>
		p.theme.media.smallOnly(css`
			top: 90px;
			padding: 0 0 20px 0;
			> div {
				padding: 0px 10px;
			}
		`)}
`;

const Content = styled.div`
	flex-grow: 1;
	font-size: 17px;
	line-height: 27px;
	${p =>
		p.theme.media.medium(css`
			display: flex !important;
			align-items: center;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			font-size: 15px;
			line-height: 22px;
		`)}
	${p =>
		p.theme.media.medium(css`
			> div {
				padding-right: 10px;
			}
		`)}
`;

const MessageItem = styled.div`
	position: relative;
	padding: 14.5px 15px;
	background-color: ${p =>
		(p.$category === 'warning' && p.theme.colors.green200) ||
		(p.$category === 'alert' && p.theme.colors.coral200) ||
		p.theme.colors.grey200};
	color: ${p =>
		(p.$category === 'warning' && p.theme.colors.green800) ||
		(p.$category === 'alert' && p.theme.colors.coral800) ||
		p.theme.colors.grey900};
	max-width: 100%;
	margin: 0 auto 10px !important;
	border: 1px solid;
	border-color: ${p =>
		(p.$category === 'warning' && p.theme.colors.green800) ||
		(p.$category === 'alert' && p.theme.colors.coral800) ||
		'transparent'};
	box-shadow: ${p => p.theme.utils.boxShadowSoft};
	border-radius: ${p => p.theme.utils.borderRadius};
	&:last-of-type:not(:first-of-type) {
		margin-bottom: 0 !important;
	}

	${p =>
		p.theme.media.medium(css`
			padding: 14.5px 55px 14.5px 15px;
			margin: 0 auto 20px !important;
		`)}

	// Sticky message with no close-button
	${p =>
		p.$isSticky &&
		css`
			padding: 10px 15px 10px 15px !important;
			> div > div {
				padding-right: 0;
			}
		`}

	p {
		margin-bottom: 5px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}

	p,
	li {
		color: ${p =>
			(p.$category === 'warning' && p.theme.colors.green200) ||
			(p.$category === 'alert' && p.theme.colors.coral200) ||
			p.theme.colors.grey200};
	}
	${Content} svg,
	${Content} svg path {
		fill: ${p =>
			(p.$category === 'warning' && p.theme.colors.green800) ||
			(p.$category === 'alert' && p.theme.colors.coral800) ||
			p.theme.colors.grey900} !important;
	}

	${p =>
		p.$category !== 'info' &&
		css`
			a:first-of-type {
				${p =>
					p.theme.media.smallOnly(css`
						padding-left: 27px;
					`)}
			}
		`}
`;

const Icon = styled.span`
	margin-right: 10px;
	text-align: left;
	display: inline-block;
	${p =>
		p.theme.media.smallOnly(css`
			float: left;
		`)}
	svg {
		width: 17px;
		height: 26px;
		text-align: left;
		display: block;
		fill: ${p => p.theme.colors.coral800};
		${p =>
			p.theme.media.smallOnly(css`
				height: 21px;
			`)}
	}
`;

const Links = styled.div`
	text-align: right;
	min-width: 130px;
	margin-left: auto;
	${p =>
		p.theme.media.smallOnly(css`
			width: 100%;
			display: inline-block;
			padding-right: 10px;
		`)}
	a, button {
		padding: 10px 10px !important;
		border: 0;
		line-height: 18px;
		font-size: 14px;
		display: inline-block;
		font-weight: 600;
		color: ${p => p.theme.colors.blue600};
		background-color: transparent;
		pointer-events: all;
		text-align: center;
		&:hover {
			background-color: white !important;
			color: ${p => p.theme.colors.blue600} !important;
			border: 0;
			text-decoration: none;
		}
		&:first-of-type:not(:last-of-type) {
			margin-bottom: 5px;
		}
	}
`;

const Actions = styled.div`
	text-align: right;
	display: inline-block;
	margin-left: auto;
	min-width: ${p => (p.$hasTwoButtons && '50px') || '30px'};
	${p =>
		p.theme.media.medium(css`
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
		`)}
	button {
		pointer-events: all;
		padding: 8px 0;
		&:first-of-type:not(:last-of-type) {
			margin-right: 10px;
		}
		&:hover {
			color: ${p => p.theme.colors.blue600} !important;
		}
	}
`;

const ContentWrap = styled.div`
	${p =>
		p.theme.media.medium(css`
			display: flex;
			align-items: center;
			flex-direction: row;
		`)}

	// Collapsable message 
	${p =>
		p.$isCollapsable &&
		css`
			display: flex;
			align-items: center;
			flex-direction: row;
			${Content} {
				max-width: 100%;
				width: 100%;
				overflow: hidden;
				${p =>
					p.theme.media.smallOnly(css`
						.is-collapsed {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					`)}
			}
			${Actions} {
				align-self: flex-end;
				.angle {
					transition: transform 250ms ease;
					transform: ${p =>
						(p.isExpanded && 'rotate(180deg)') || 'rotate(-90deg)'};
				}
			}
		`}
	
	// If message is not collapsable 
	${p =>
		!p.$isCollapsable &&
		css`
			${Actions} {
				position: absolute;
				bottom: 10px;
				right: 15px;
				button {
					padding: 7px 0;
				}
			}
			${Links} {
				margin-left: auto;

				${p =>
					p.theme.media.smallOnly(css`
						padding-right: 25px;
					`)}
			}
		`}
`;

const CloseMessage = styled(BlankButton)`
	background-color: transparent;
	border: 0;
	color: ${p => p.theme.colors.black} !important;
	padding: 0;
	cursor: pointer;
	${p =>
		p.theme.media.medium(css`
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 15px;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			vertical-align: sub;
		`)}
	&:hover {
		cursor: pointer;
		color: ${p => p.theme.colors.grey900} !important;
	}
	svg {
		width: 20px;
		height: 20px;
		display: block;
		path {
			fill: ${p => p.theme.colors.black} !important;
		}
	}
`;

const ToggleMessage = styled(BlankButton)`
	background-color: transparent;
	border: 0;
	color: ${p => p.theme.colors.black} !important;
	padding: 0;
	cursor: pointer;
	${p =>
		p.theme.media.medium(css`
			display: none !important;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			vertical-align: sub;
		`)}
	&:hover {
		cursor: pointer;
		color: ${p => p.theme.colors.grey900} !important;
	}
	svg {
		width: 20px;
		height: 20px;
		display: block;
		path {
			fill: ${p => p.theme.colors.black} !important;
		}
	}
`;
//#endregion

export default function Snackbar({
	messages = [],
	onClose = () => {},
	...props
}) {
	const [topOffset, setTopOffset] = useState(0);

	useEffect(() => {
		const crisisMessagesDiv = document.getElementById(
			'crisis-messages-list'
		);
		const allCrisisMessagesInDoc = document.querySelectorAll(
			'#crisis-messages-list .crisis-message'
		);

		if (!crisisMessagesDiv || !allCrisisMessagesInDoc?.length > 0) return;

		// If crisismessages-div has height then save to state
		if (crisisMessagesDiv.offsetHeight)
			setTopOffset(crisisMessagesDiv.offsetHeight);
		//eslint-disable-next-line
	}, []);

	if (!messages?.length > 0) return;

	return (
		<Wrap id="snackbar" role="alert" $topOffset={topOffset} {...props}>
			<MaxWidth>
				{messages.map((item, i) => (
					<Message
						key={item?._id || i}
						data={item}
						onClose={onClose}
					/>
				))}
			</MaxWidth>
		</Wrap>
	);
}

function Message({ data, onClose }) {
	const [isExpanded, setIsExpanded] = useState(false);
	const isCollapsable = data?.text?.length > 70 || false;

	return (
		<MessageItem
			id={data._id}
			category={data?.category || 'info'}
			$isCollapsable={isCollapsable}
			dataset-uniqueid={data._id}
			data-cy="snackbar-message"
			$sticky={!!data?.sticky}>
			<ContentWrap
				$isCollapsable={isCollapsable}
				$isSticky={!!data?.sticky}
				$isExpanded={isExpanded}>
				<Content>
					{(data?.category === 'warning' && (
						<Icon>
							<FontAwesomeIcon icon={faCircleCheck} />
						</Icon>
					)) ||
						(data?.category === 'alert' && (
							<Icon>
								<IconAlert size="xl" type="regular" />
							</Icon>
						))}
					{data?.text && (
						<SlideUpDown
							isCollapsable={isCollapsable}
							isExpanded={isExpanded}>
							{data?.text}
						</SlideUpDown>
					)}
				</Content>
				<Actions $hasTwoButtons={isCollapsable && !data?.sticky}>
					{isCollapsable && (
						<ToggleMessage
							onClick={() => setIsExpanded(!isExpanded)}
							data-cy="toggle-snackbar-message"
							isExpanded={isExpanded}
							title={`${
								(isExpanded && 'Se mindre av') || 'Vis hele'
							} meldingen`}>
							<IconAngleDown
								className="angle"
								aria-label={`Klikk for å ${
									(isExpanded && 'se mindre av') ||
									'vise hele'
								} meldingen`}
								size="xs"
							/>
						</ToggleMessage>
					)}

					{!data?.sticky && (
						<CloseMessage
							id="close-snackbar-message"
							onClick={() => onClose(data._id)}
							data-cy="close-snackbar-message"
							title="Lukk">
							<IconClose
								className="close"
								aria-label="Klikk for å lukke meldingen"
								size="xs"
							/>
						</CloseMessage>
					)}
				</Actions>
			</ContentWrap>
		</MessageItem>
	);
}

function SlideUpDown({
	isCollapsable = false,
	isExpanded = false,
	openHeight = 'auto',
	children,
}) {
	const { windowWidth } = useWindow();

	if (!isCollapsable || windowWidth >= 767) return children;

	return (
		<AnimatePresence initial={false}>
			<motion.div
				initial="collapsed"
				animate={isExpanded ? 'open' : 'collapsed'}
				exit="collapsed"
				variants={{
					open: { height: openHeight },
					collapsed: { height: 22 },
				}}
				transition={{
					duration: 0.25,
				}}>
				{children}
			</motion.div>
		</AnimatePresence>
	);
}
